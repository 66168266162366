import { dateTime, type TimeRange } from '@grafana/data';
import { fromUnixTime } from 'date-fns';

export const checkPartialData = (startTimeUnix: number | undefined, timeRange: TimeRange) => {
  if (!startTimeUnix) {
    return {
      partialData: false,
      noDataThisMonth: false,
      noTimestamp: true,
      fullBillingMonth: timeRange.from,
    };
  }
  const startDate = dateTime(fromUnixTime(startTimeUnix));
  // adding a month to startDate and truncating to the end of it will show since when data is available.
  const fullDataCutoff = dateTime(startDate).utc().add(1, 'month').startOf('month');

  return {
    partialData: !startDate.isBefore(timeRange.from),
    noDataThisMonth: !startDate.isBefore(timeRange.to),
    noTimestamp: false,
    fullBillingMonth: fullDataCutoff,
  };
};

export function createMonthRange(monthAgo: number): TimeRange {
  const now = dateTime().utc();
  return {
    from: dateTime(now).subtract(monthAgo, 'month').startOf('month'),
    to: dateTime(now).subtract(monthAgo, 'month').endOf('month'),
    raw: {
      from: `now-${monthAgo}M/M`,
      to: `now-${monthAgo}M/M`,
    },
  };
}
