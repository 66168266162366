import pluginJson from '../plugin.json';

export const PLUGIN_BASE_URL = `/a/${pluginJson.id}`;

export enum ROUTES {
  Attributions = 'attributions',
  Overview = 'overview',
  Configuration = 'configuration',
  Metrics = 'metrics',
}

export enum API_ROUTES {
  Labels = 'costattributionlabelnames',
  HardcodedLabel = 'hardcodedLabel', // PUT calls require a hardcoded subroute url
}
