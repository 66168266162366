import { CostAttributionLabelName } from 'generated/costattributionlabelname/v2/costattributionlabelname_object_gen';
import { CostAttributionLabelNameListResponse } from 'types';
import { getPluginAppPlatformUrl } from 'utils/utils.api';
import { API_ROUTES } from 'constants/routes';
import { useQuery, UseQueryResult, useMutation, UseMutationResult } from '@tanstack/react-query';

export const useGetLabels = (): UseQueryResult<
  CostAttributionLabelNameListResponse<CostAttributionLabelName>,
  Error
> => {
  return useQuery({
    queryKey: ['labels'],
    queryFn: getLabels,
    retry: false,
  });
};

const getLabels = async () => {
  const response = await fetch(getPluginAppPlatformUrl(`${API_ROUTES.Labels}`));

  if (!response.ok) {
    // Throw an error with the response status and status text
    throw new Error(`${response.status} ${response.statusText}`);
  }

  const res = await response.json();
  return res;
};

export const usePostLabels = (): UseMutationResult<
  CostAttributionLabelNameListResponse<CostAttributionLabelName> | undefined,
  Error,
  { data: CostAttributionLabelNameListResponse<CostAttributionLabelName>; labelName: string }
> => {
  return useMutation<
    CostAttributionLabelNameListResponse<CostAttributionLabelName>,
    Error,
    { data: CostAttributionLabelNameListResponse<CostAttributionLabelName>; labelName: string }
  >({
    mutationKey: ['labels'],
    mutationFn: postLabels,
  });
};

const postLabels = async ({
  data,
  labelName,
}: {
  data: CostAttributionLabelNameListResponse<CostAttributionLabelName> | undefined;
  labelName: string;
}): Promise<CostAttributionLabelNameListResponse<CostAttributionLabelName>> => {
  if (!data) {
    // Throw an error with the response status and status text
    throw new Error(`Data is undefined`);
  }

  const newCostAttributionLabelName = {
    kind: data.items[0].kind,
    apiVersion: data.items[0].apiVersion,
    spec: {
      labelName: labelName,
    },
    metadata: {
      name: API_ROUTES.HardcodedLabel,
      namespace: data.items[0].metadata.namespace || '',
    },
  };

  const response = await fetch(getPluginAppPlatformUrl(`${API_ROUTES.Labels}/${API_ROUTES.HardcodedLabel}`), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newCostAttributionLabelName),
  });

  if (!response.ok) {
    // Throw an error with the response status and status text
    throw new Error(`${response.status} ${response.statusText}`);
  }

  const res = await response.json();
  return res;
};

export const usePutLabels = (): UseMutationResult<
  CostAttributionLabelNameListResponse<CostAttributionLabelName> | undefined,
  Error,
  { data: CostAttributionLabelNameListResponse<CostAttributionLabelName>; labelName: string }
> => {
  return useMutation<
    CostAttributionLabelNameListResponse<CostAttributionLabelName>,
    Error,
    { data: CostAttributionLabelNameListResponse<CostAttributionLabelName>; labelName: string }
  >({
    mutationKey: ['labels'],
    mutationFn: putLabels,
  });
};

export const putLabels = async ({
  data,
  labelName,
}: {
  data: CostAttributionLabelNameListResponse<CostAttributionLabelName> | undefined;
  labelName: string;
}): Promise<CostAttributionLabelNameListResponse<CostAttributionLabelName>> => {
  if (!data) {
    // Throw an error if data is undefined
    throw new Error(`Data is undefined`);
  }

  const newCostAttributionLabelName = {
    kind: data.items[0].kind,
    apiVersion: data.items[0].apiVersion,
    spec: {
      labelName: labelName,
    },
    metadata: {
      name: 'test',
      namespace: data.items[0].metadata.namespace,
    },
  };

  const response = await fetch(getPluginAppPlatformUrl(`${API_ROUTES.Labels}/test`), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newCostAttributionLabelName),
  });

  if (!response.ok) {
    // Throw an error with the response status and status text
    throw new Error(`${response.status} ${response.statusText}`);
  }

  const res = await response.json();
  return res;
};
