import { EmbeddedScene, SceneFlexLayout } from '@grafana/scenes';
import { Attribution } from 'types';
import { attributionsRow } from './attributionsRow';
import { usageRow } from './usageRow';

export const getScene = (
  records: Attribution[] | undefined,
  metricLabel: string | undefined,
  loading: boolean,
  hmTenantId: string
) => {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      direction: 'column',
      children: [usageRow(hmTenantId, records), ...attributionsRow(records, metricLabel, loading)],
    }),
  });
};
