import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES } from 'constants/routes';
import { AttributionsPage } from 'pages/Attributions';
import { OverviewPage } from 'pages/Overview/Overview';
import { ConfigurationPage } from 'pages/Configuration/Configuration';

export const Routes = () => {
  return (
    <Switch>
      <Route path={prefixRoute(`${ROUTES.Metrics}`)} component={AttributionsPage} />
      <Route path={prefixRoute(`${ROUTES.Overview}`)} component={OverviewPage} />
      <Route path={prefixRoute(`${ROUTES.Configuration}`)} component={ConfigurationPage} />
      <Redirect to={prefixRoute(ROUTES.Overview)} />
    </Switch>
  );
};
