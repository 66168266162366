import { SceneQueryRunner, SceneTimeRange, SceneFlexLayout, SceneFlexItem } from '@grafana/scenes';
import { BillableSeriesTotalThisMonth, SumBillableSeriesByMonth } from './panels';
import { Attribution } from 'types';

export function usageRow(hmTenantId: string, records: Attribution[] | undefined) {
  const usageQueryConfig = {
    datasource: { uid: 'grafanacloud-usage' },
    queries: [
      {
        refId: 'A',
        datasource: { uid: 'grafanacloud-usage' },
        expr: `
          max (grafanacloud_instance_billable_usage{id="${hmTenantId}" })
          and day_of_month() == 1 and hour() == 0
        `,
        legendFormat: 'Billable series',
      },
    ],
  };

  // TODO: Consider replicating the "Query Options: Time shift" UI config:
  // ie. In the JSON for the panel: `"timeFrom": "2M",`
  // So that we don't blow away the outer context completely?
  const usageQueryRunner3M = new SceneQueryRunner({
    ...usageQueryConfig,
    $timeRange: new SceneTimeRange({ from: 'now-3M', to: 'now', timeZone: 'utc' }),
  });
  const usageQueryRunner2M = new SceneQueryRunner({
    ...usageQueryConfig,
    $timeRange: new SceneTimeRange({ from: 'now-2M', to: 'now', timeZone: 'utc' }),
  });

  return new SceneFlexLayout({
    children: [
      new SceneFlexItem({
        $data: usageQueryRunner2M,
        width: '33%',
        md: {
          width: '100%',
        },
        minHeight: '250px',
        body: BillableSeriesTotalThisMonth(records),
      }),
      new SceneFlexItem({
        $data: usageQueryRunner3M,
        width: '67%',
        md: {
          width: '100%',
        },
        minHeight: '250px',
        body: SumBillableSeriesByMonth(),
      }),
    ],
  });
}
