import { parse, getYear, format } from 'date-fns';
import { getValueFormat, type DateTime } from '@grafana/data';
import { Attribution, type DimLabel } from 'types';

export const formatTitleDate = (records: Attribution[]) => {
  const lastMonth = findLastMonth(records)?.['Month(ISO)'] ?? null;

  const date = lastMonth ? parse(lastMonth, 'yyyy-MM', new Date()) : null;
  if (!date) {
    return 'this month';
  }
  const year = getYear(date);
  const monthName = format(date, 'MMMM');

  return `${monthName} ${year}`;
};

// Find last month in 3 months of data 'Month(ISO)' no matter what order is returned from the api
export const findLastMonth = (arr: Attribution[]) => {
  if (arr.length === 0) {
    return null;
  }

  return arr.reduce((earliest, current) => {
    const latestDate = new Date(earliest['Month(ISO)']);
    const currentDate = new Date(current['Month(ISO)']);

    return currentDate > latestDate ? current : earliest;
  });
};

// Filter Attribution data to only the last months worth of data
export const filterSelectedMonth = (data: Attribution[], selectedMonth: DateTime) => {
  const isoSelectedMonth = selectedMonth.format('yyyy-MM');
  const dataMatchingMonth = data.filter((row) => {
    return row['Month(ISO)'] === isoSelectedMonth;
  });

  return dataMatchingMonth;
};

const currencyFormatter = getValueFormat('currencyUSD');

export const unFormatDollarCost = (value: number, seriesSum: number, total: number) => {
  seriesSum = isNaN(seriesSum) ? 0 : seriesSum;

  // No data return total bill as zero
  if (seriesSum === 0) {
    return seriesSum;
  }

  return parseFloat(((value / seriesSum) * total).toFixed(2));
};

export const seriesBytesPerBillingBytes = (value: number, seriesSum: number, total: number) => {
  seriesSum = isNaN(seriesSum) ? 0 : seriesSum;

  // No data return total bill as zero
  if (seriesSum === 0) {
    return seriesSum;
  }
  // Billing series totals convert to gigabytes
  const totalBytes = total * 1024 * 1024 * 1024;

  return (value / seriesSum) * totalBytes;
};

export const formatDollarCost = (dollarValue: number) => {
  const { prefix, text, suffix } = currencyFormatter(dollarValue);
  return `${prefix}${text}${suffix}`;
};
export const bytesFormatter = getValueFormat('bytes');

export const bytesFormat = (value: number) => {
  const { text, suffix } = bytesFormatter(value);
  return `${text}${suffix}`;
};

export const shortFormatter = getValueFormat('short');
export const shortFormat = (value: number) => {
  const { text, suffix } = shortFormatter(value);
  return `${text}${suffix}`;
};

export function formatMetricLabelPrefix (label: string): DimLabel {
  return `label_${label}`
};
