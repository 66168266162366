import type { DataFrame } from '@grafana/data';

export function emptyBillableDataframe(labels: string[]) {
  return {
    fields: [
      ...labels.map((label) => ({
        name: columnHeader(label),
        type: 'string',
        state: {},
        values: [] as string[],
        config: {},
      })),
    ],
    length: 0,
  } as DataFrame;
}

export function columnHeader(label: string) {
  return `Label: ${label}`;
}
