import React from 'react';

export const NoAttributions = () => {
  return (
    <div>
      <p>There are no attributions.</p>
      <p>
        <a
          className="external-link"
          href="https://grafana.com/docs/grafana-cloud/cost-management-and-billing/generate-usage-attribution-report/"
        >
          Learn how to generate usage attribution reports.
        </a>
      </p>
    </div>
  );
};
