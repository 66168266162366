import React, { useEffect, useMemo } from 'react';
import {
  SceneDataNode,
  EmbeddedScene,
  SceneFlexLayout,
  SceneFlexItem,
  PanelBuilders,
  type VizPanelBuilder,
} from '@grafana/scenes';
import { LoadingState, PanelData, type TimeRange } from '@grafana/data';
import {
  BigValueColorMode,
  BigValueGraphMode,
  BigValueJustifyMode,
  BigValueTextMode,
  PercentChangeColorMode,
  ThresholdsMode,
  VizOrientation,
} from '@grafana/schema';
import type { Options } from '@grafana/schema/dist/esm/raw/composable/stat/panelcfg/x/StatPanelCfg_types.gen';

type Props = {
  totalBillPanelData: PanelData | undefined;
  metricTotalBillPanelData: PanelData | undefined;
  logsTotalBillPanelData: PanelData | undefined;
  tracesTotalBillPanelData: PanelData | undefined;
  timeRange: TimeRange;
};

function buildDataNode(data: PanelData | undefined, timeRange: TimeRange) {
  return new SceneDataNode({
    data: {
      state: data?.state || LoadingState.Loading,
      series: data?.series || [],
      timeRange,
      errors: data?.errors || undefined,
    },
  });
}

function buildDollarFormat(panel: VizPanelBuilder<Options, any>) {
  return panel
    .setThresholds({
      mode: ThresholdsMode.Absolute,
      steps: [
        {
          color: 'text',
          value: 0,
        },
      ],
    })
    .setColor({
      fixedColor: 'dark-blue',
      mode: 'fixed',
    })
    .setUnit('currencyUSD')
    .setHoverHeader(true)
    .setOption('reduceOptions', {
      values: false,
      calcs: ['lastNotNull'],
      fields: '',
    })
    .setNoValue('$0')
    .setOption('colorMode', BigValueColorMode.Background)
    .setOption('orientation', VizOrientation.Horizontal)
    .setOption('wideLayout', true)
    .setOption('textMode', BigValueTextMode.Auto)
    .setOption('graphMode', BigValueGraphMode.Area)
    .setOption('justifyMode', BigValueJustifyMode.Center)
    .setOption('showPercentChange', false)
    .setOption('percentChangeColorMode', PercentChangeColorMode.Standard)
    .setOption('text', {
      titleSize: 16,
      valueSize: 48,
    });
}

const defaultStatOptions = {
  md: {
    width: '100%',
  },
  width: '25%',
  height: 300,
};

export function useGetScene({
  totalBillPanelData,
  metricTotalBillPanelData,
  logsTotalBillPanelData,
  tracesTotalBillPanelData,
  timeRange,
}: Props) {
  const sceneObjects = useMemo(() => {
    const totalDataNode = buildDataNode(totalBillPanelData, timeRange);

    const totalBody = PanelBuilders.stat();
    const totalSceneItem = new SceneFlexItem({
      ...defaultStatOptions,
      $data: totalDataNode,
      body: buildDollarFormat(totalBody).build(),
    });

    const metricsDataNode = buildDataNode(metricTotalBillPanelData, timeRange);

    const metricsBody = PanelBuilders.stat();
    const metricSceneItem = new SceneFlexItem({
      ...defaultStatOptions,
      $data: metricsDataNode,
      body: buildDollarFormat(metricsBody).build(),
    });

    const logsDataNode = buildDataNode(logsTotalBillPanelData, timeRange);
    const logsBody = PanelBuilders.stat();
    const logsSceneItem = new SceneFlexItem({
      ...defaultStatOptions,
      $data: logsDataNode,
      body: buildDollarFormat(logsBody).build(),
    });

    const tracesDataNode = buildDataNode(tracesTotalBillPanelData, timeRange);
    const tracesSceneItem = new SceneFlexItem({
      ...defaultStatOptions,
      $data: tracesDataNode,
      body: buildDollarFormat(PanelBuilders.stat()).build(),
    });

    const allScenes = new EmbeddedScene({
      body: new SceneFlexLayout({
        children: [totalSceneItem, metricSceneItem, logsSceneItem, tracesSceneItem],
      }),
    });

    return {
      totalDataNode,
      tracesDataNode,
      logsDataNode,
      metricsDataNode,
      allScenes,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sceneObjects.totalDataNode.setState({
      data: {
        state: totalBillPanelData?.state || LoadingState.Loading,
        series: totalBillPanelData?.series || [],
        timeRange,
      },
    });
    sceneObjects.metricsDataNode.setState({
      data: {
        state: metricTotalBillPanelData?.state || LoadingState.Loading,
        series: metricTotalBillPanelData?.series || [],
        timeRange,
      },
    });
    sceneObjects.logsDataNode.setState({
      data: {
        state: logsTotalBillPanelData?.state || LoadingState.Loading,
        series: logsTotalBillPanelData?.series || [],
        timeRange,
      },
    });
    sceneObjects.tracesDataNode.setState({
      data: {
        state: tracesTotalBillPanelData?.state || LoadingState.Loading,
        series: tracesTotalBillPanelData?.series || [],
        timeRange,
      },
    });
  }, [
    totalBillPanelData,
    metricTotalBillPanelData,
    logsTotalBillPanelData,
    tracesTotalBillPanelData,
    timeRange,
    sceneObjects,
  ]);

  return sceneObjects.allScenes;
}

export const StatsScene = ({
  totalBillPanelData,
  metricTotalBillPanelData,
  logsTotalBillPanelData,
  tracesTotalBillPanelData,
  timeRange,
}: Props) => {
  const scene = useGetScene({
    totalBillPanelData,
    metricTotalBillPanelData,
    logsTotalBillPanelData,
    tracesTotalBillPanelData,
    timeRange,
  });

  return <scene.Component model={scene} />;
};
