import { SceneContextProvider } from '@grafana/scenes-react';
import React from 'react';

export interface Props {
  children: React.ReactNode;
}

export const SceneContext = ({ children }: Props) => {
  return <SceneContextProvider timeRange={{ from: 'now-1M/M', to: 'now-1M/M' }}>{children}</SceneContextProvider>;
};
