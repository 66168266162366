import { config } from '@grafana/runtime';
import pluginJson from 'plugin.json';

// Fetch needs a / to work
export const buildUrl = () => {
  let buildUrl = config.appUrl;
  if (buildUrl.at(-1) !== '/') {
    // ensures that the API pathname is appended correctly (buildUrl seems to always have it but better to be extra careful)
    buildUrl += '/';
  }

  return buildUrl;
};

// Prefix the proxy api path
export const getPluginApiProxyUrl = () => {
  let url = buildUrl();
  const apiBaseUrl = new URL(`api/plugin-proxy/${pluginJson.id}/attribution`, url);

  return apiBaseUrl;
};

// Prefix the app platform api path
export const getPluginAppPlatformUrl = (param: string) => {
  let url = buildUrl();
  const apiBaseUrl = new URL(`api/plugins/${pluginJson.id}/resources/v2/${param}`, url);

  return apiBaseUrl;
};
