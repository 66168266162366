import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

export const useStyles = () => {
  return useStyles2((theme: GrafanaTheme2) => {
    const mediaQuery = `@media (min-width: ${theme.breakpoints.values.sm}px)`;

    const overviewSceneSm = {
      display: 'block',
      gridTemplateColumns: '1fr',
    };

    const overviewTableSm = {
      paddingTop: theme.spacing(1),
      gridTemplateColumns: '1fr',
    };

    return {
      tabsContainer: css({
        paddingTop: theme.spacing(3),
      }),
      width50: css({
        width: '50%',
      }),
      overviewScene: css({
        paddingTop: theme.spacing(2),
        display: 'grid',
        flexGrow: 1,
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
        gridAutoRows: '320px',
        columnGap: theme.spacing(1),
        rowGap: theme.spacing(1),
        [mediaQuery]: overviewSceneSm,
      }),
      overviewTableGrid: css({
        display: 'grid',
        gridTemplateColumns: '1fr',
        flexGrow: 1,
        minHeight: '500px',
        [mediaQuery]: overviewTableSm,
      }),
    };
  });
};
