import React, { ReactNode } from 'react';

import { ConfigContextProvider } from './ConfigContext';

type Props = {
  children: ReactNode[] | ReactNode;
};

export const StoreContext = ({ children }: Props) => {
  return <ConfigContextProvider>{children}</ConfigContextProvider>;
};
