import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SceneDataNode, SceneFlexLayout, EmbeddedScene, SceneFlexItem, PanelBuilders } from '@grafana/scenes';
import { LoadingState, DataFrame, ThresholdsMode, FieldType, type TimeRange } from '@grafana/data';
import { useStyles } from 'styles';
import { testIds } from 'components/testIds';
import { LOADING_TABLE_DATA, TABLE_COLUMNS } from 'constants/constant';
import {
  BarGaugeDisplayMode,
  BarGaugeValueMode,
  TableCellDisplayMode,
  TableCellBackgroundDisplayMode,
  TableCellHeight,
} from '@grafana/schema';

type Props = {
  dataNodes: SceneDataNode[];
  dataFrames: DataFrame[][];
  showFooter?: boolean;
  dateString: string;
};

function getDataNodes(dataFramesLastMonth: DataFrame[][], loading: boolean, timeRange: TimeRange) {
  return dataFramesLastMonth.map((df, i) => {
    return new SceneDataNode({
      data: {
        state: loading ? LoadingState.Loading : LoadingState.Done,
        series: [df[0]],
        timeRange,
      },
    });
  });
}

export function buildSceneBody(dateString: string, showFooter = true) {
  return PanelBuilders.table()
    .setTitle(`Cost attributions ${dateString}`)
    .setDescription(
      'Dollar values presented in the table are allocated based on the total invoice amount and proportion of unit count.'
    )
    .setOption('showHeader', true)
    .setOption('cellHeight', TableCellHeight.Sm)
    .setOption('footer', { show: showFooter, reducer: ['sum'], countRows: false, fields: [] })
    .setCustomFieldConfig('align', 'auto')
    .setCustomFieldConfig('cellOptions', {
      type: TableCellDisplayMode.Auto,
      wrapText: false,
    })
    .setUnit('locale')
    .setCustomFieldConfig('inspect', false)
    .setThresholds({
      mode: ThresholdsMode.Percentage,
      steps: [
        {
          color: 'dark-blue',
          value: 0,
        },
        {
          color: 'semi-dark-blue',
          value: 10,
        },
        {
          color: 'blue',
          value: 30,
        },
        {
          color: 'light-blue',
          value: 40,
        },
        {
          color: 'dark-yellow',
          value: 50,
        },
        {
          color: 'dark-orange',
          value: 70,
        },
        {
          color: 'dark-red',
          value: 90,
        },
      ],
    })
    .setOverrides((builder) => {
      builder
        .matchFieldsWithNameByRegex(`/Logs/`)
        .overrideUnit('bytes')
        .matchFieldsWithNameByRegex(`/Traces/`)
        .overrideUnit('bytes')
        .matchFieldsWithNameByRegex(`/Metrics/`)
        .overrideUnit('short')
        .matchFieldsWithNameByRegex(`/.* cost/`)
        .overrideUnit('currencyUSD')
        .overrideDecimals(2)
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.Gauge,
          valueDisplayMode: BarGaugeValueMode.Text,
          mode: BarGaugeDisplayMode.Gradient,
        })
        .overrideMin(0)
        .overrideCustomFieldConfig('align', 'left')
        .matchFieldsByType(FieldType.string)
        .overrideCustomFieldConfig('filterable', true)
        .matchFieldsWithName(TABLE_COLUMNS.Total)
        .overrideMin(0)
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorBackground,
          mode: TableCellBackgroundDisplayMode.Basic,
        });
    })
    .build();
}
export function getScene({ dateString, dataNodes, dataFrames, showFooter }: Props) {
  const frames = dataFrames.map((df, i) => {
    return new SceneFlexItem({
      width: '100%',
      $data: dataNodes[i],
      height: 400,
      body: buildSceneBody(dateString, showFooter),
    });
  });

  return new EmbeddedScene({
    body: new SceneFlexLayout({
      children: [...frames],
    }),
  });
}

type TableSceneProps = {
  dataFrames: DataFrame[][];
  loading: boolean;
  timeRange: TimeRange;
  dateString: string;
};

export const TableScene = ({ dataFrames, loading, timeRange, dateString }: TableSceneProps) => {
  const styles = useStyles();
  const firstLoad = useRef(true);
  const hasFooter = dataFrames !== LOADING_TABLE_DATA;
  const [dataNodes, setDataNodes] = useState(getDataNodes(dataFrames, loading, timeRange));
  const initScene = useMemo(() => {
    return getScene({
      dataNodes,
      dataFrames,
      showFooter: hasFooter,
      dateString,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [scene, setScene] = useState(initScene);

  useEffect(() => {
    if (!firstLoad.current) {
      const initDataNodes = getDataNodes(dataFrames, loading, timeRange);
      setDataNodes(initDataNodes);
      setScene(
        getScene({
          dataNodes: initDataNodes,
          dataFrames,
          showFooter: hasFooter,
          dateString,
        })
      );
    }
    firstLoad.current = false;
    // We do not want to run this effect everytime the dataFrames change, only when the
    // scene body needs to be re-built
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFooter, dateString]);

  useEffect(() => {
    dataNodes.forEach((node, i) => {
      node.setState({
        data: {
          state: loading ? LoadingState.Loading : LoadingState.Done,
          series: [dataFrames[i][0]],
          timeRange,
        },
      });
    });
  }, [dataFrames, dataNodes, loading, timeRange]);

  const component = useMemo(() => {
    return <scene.Component model={scene} />;
  }, [scene]);

  return (
    <div className={styles.overviewTableGrid} data-testid={testIds.overviewTab.table}>
      {component}
    </div>
  );
};
