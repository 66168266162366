import React from 'react';

import { css } from '@emotion/css';
import SVG from 'react-inlinesvg';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, Text, LinkButton } from '@grafana/ui';
import pluginJson from '../../plugin.json';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    graphicContainer: css({
      display: 'flex',
      justifyContent: 'center',
    }),
    graphic: css({ height: '250px', padding: theme.spacing(1) }),
    text: css({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }),
  };
};

type Props = {
  description?: string;
  cta?: {
    text: string;
    url: string;
  };
};

export const GrotEmpty = ({ description, cta }: Props) => {
  const styles = useStyles2(getStyles);

  return (
    <>
      <div className={styles.graphicContainer}>
        <SVG className={styles.graphic} src={`/public/plugins/${pluginJson.id}/img/grot-empty.svg`} />
      </div>
      <div className={styles.text}>
        {description && (
          <p>
            <Text textAlignment="center">{description}</Text>
          </p>
        )}
        {cta && (
          <Text textAlignment="center" color="primary" element="p">
            <LinkButton href={cta.url}>{cta.text}</LinkButton>
          </Text>
        )}
      </div>
    </>
  );
};
