import _ from 'lodash';
import { Attribution, DimLabel, isDimLabel } from 'types';

// getDimensions returns an array of attribution dimensions derived from the records
export function getDimensions(records: Attribution[]): DimLabel[] {
  return Array.from(Object.keys(records[0]).filter(isDimLabel));
}

export function getDimsNamesAndValues(records: Attribution[]) {
  const dimNamesAndValues = new Map<DimLabel, string[]>();

  for (const labelName of getDimensions(records)) {
    dimNamesAndValues.set(labelName, _.uniq<string>(records.map((record: any) => record[labelName])));
  }

  return dimNamesAndValues;
}
