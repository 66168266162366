export const testIds = {
  appConfig: {
    container: 'data-testid ac-container',
    apiKey: 'data-testid ac-api-key',
    apiUrl: 'data-testid ac-api-url',
    submit: 'data-testid ac-submit-form',
  },
  attributionTab: {
    container: 'data-testid attribution-container',
  },
  overviewTab: {
    container: 'data-testid overview-container',
    table: 'data-testid overview-table',
    stats: 'data-testid overview-stats',
  },
  configurationTab: {
    container: 'data-testid configuration-container',
    metricLabel: 'data-testid configuration-metric-label',
    submit: 'data-testid configuration-submit-form',
  },
};
