import { cloneDeep } from 'lodash';
import { DataFrame, FieldType, PanelData, Field } from '@grafana/data';
import { Attribution, type DimLabel, type FixedLabel, type Label, type ProductErrors } from 'types';
import { transformer } from 'transformations/attributions';

import {
  BILLABLE_SERIES,
  FIELD_NAME_APPROXIMATE_COST,
  FIELD_NAME_BILLABLE_METRIC,
  FIELD_NAME_BYTES,
  FIELD_NAME_VALUE,
  MONTH_ISO,
  OVERFLOW_VALUE,
  TABLE_COLUMNS,
  UNATTRIBUTED,
  UNATTRIBUTED_VALUE,
} from 'constants/constant';
import { emptyBillableDataframe } from './utils.defaults';
import type { PartialData } from '../pages/Overview/hooks/overviewData';
import {
  formatDollarCost,
  formatMetricLabelPrefix,
  seriesBytesPerBillingBytes,
  unFormatDollarCost,
} from './utils.formating';

// Get total panel cost number
const getTotalCost = (fields: Field[] | null) => {
  if (fields) {
    for (const field of fields) {
      if (field.name === FIELD_NAME_VALUE) {
        const total = field.values[field.values.length - 1];
        return Number.isNaN(total) ? 0 : total;
      }
    }
  }
  return 0;
};

export function getFieldValuesFromMatchingAllLabels(
  productPanelData: PanelData,
  labelIndexMap: Record<string, number>,
  attributionLabels: string[]
) {
  const indexes = Object.values(labelIndexMap);
  const numberOfRows = indexes.length ? Math.max(...indexes) + 1 : 0;
  const valuesList: number[] = new Array(numberOfRows).fill(0);
  productPanelData.series.forEach((series) => {
    series.fields.forEach((field) => {
      if (field.name === FIELD_NAME_VALUE) {
        if (field.labels) {
          const { key } = getKeyFromLabels(attributionLabels, field);
          if (labelIndexMap[key] !== undefined) {
            valuesList[labelIndexMap[key]] = valuesList[labelIndexMap[key]] + field.values[field.values.length - 1];
          }
        }
      }
    });
  });
  return valuesList;
}

export function getKeyFromLabels(labelNames: string[], field: Field) {
  const allLabelValues = labelNames.map((name) => {
    const value = field.labels![name];
    return !value || value === UNATTRIBUTED_VALUE ? '' : value;
  });
  return { values: allLabelValues, key: makeRowKey(allLabelValues) };
}

function makeRowKey(labelValues: string[]) {
  return labelValues.join('-');
}

export interface TransformOptions {
  metricAttributions: Attribution[] | null;
  metricTotalBillPanelData: PanelData;
  logsAttributionData: PanelData;
  logsAttributionDataTotal: PanelData;
  logsTotalBillPanelData: PanelData;
  logsBillingDataTotal: PanelData;
  tracesTotalBillPanelData: PanelData;
  tracesAttributionData: PanelData;
  tracesAttributionDataTotal: PanelData;
  tracesBillingDataTotal: PanelData;
  isPartialData: PartialData;
  isCurrentMonth: boolean;
  attributionLabels: string[];
  errors: ProductErrors;
}

export const buildTableAndCSVDataFrames = async (
  options: TransformOptions
): Promise<{ tableData: DataFrame[][]; csvData: DataFrame[][]; isInOverflow: IsOverflow }> => {
  const {
    metricAttributions,
    metricTotalBillPanelData,
    logsTotalBillPanelData,
    logsAttributionData,
    logsAttributionDataTotal,
    logsBillingDataTotal,
    tracesTotalBillPanelData,
    tracesAttributionData,
    tracesAttributionDataTotal,
    tracesBillingDataTotal,
    isPartialData,
    isCurrentMonth,
    attributionLabels,
    errors,
  } = options;
  
  const isMetrics = Boolean(metricAttributions?.length)
  const isLogs = logsAttributionData?.series[0]?.fields.length > 0 || false;
  const isTraces = tracesAttributionData?.series[0]?.fields.length > 0 || false;

  // Get column label headers
  let tableDataFrame = emptyBillableDataframe(attributionLabels);

  let metricData = null;
  if (!isCurrentMonth && isMetrics && !errors.metrics && metricAttributions) {
    const metricAttributionsFrame = convertMetricAttributionsToDataFrame(metricAttributions, attributionLabels);
    const transformedDataFrame = await transformer(
      [addFieldForLabelIfMissing(metricAttributionsFrame, attributionLabels)],
      attributionLabels
    );
    metricData = transformedDataFrame[0];
  }

  const { labelIndexMap, labelValues, isInOverflow } = getAllLabelColumnValues(
    metricData,
    logsAttributionData,
    tracesAttributionData,
    attributionLabels,
    errors,
  );

  tableDataFrame.fields.forEach((field, i) => {
    field.values = labelValues[i] || [];
  });

  // update empty values to be 'unattributed' in the view
  tableDataFrame.fields.forEach((field, i) => {
    field.values = field.values.map((value) => (value === '' ? UNATTRIBUTED : value));
  });

  const labelLength = tableDataFrame.fields[0].values.length;

  // Clone the transformedData object to output table and csv
  const tableDF = cloneDeep(tableDataFrame);
  const csvDF = cloneDeep(tableDataFrame);

  const [tableLabelFields] = tableDF.fields.filter((field) => field.name !== FIELD_NAME_BILLABLE_METRIC);
  const [csvLabelFields] = csvDF.fields.filter((field) => field.name !== FIELD_NAME_BILLABLE_METRIC);

  if (metricData && !isInOverflow.isMetricsOverflow && !errors.metrics) {
    const metricColumns = getMetricColumns(
      metricData,
      metricTotalBillPanelData,
      !isCurrentMonth && isMetrics,
      labelLength
    );
    tableDF.fields.push(...metricColumns.tableFields);
    csvDF.fields.push(...metricColumns.csvFields);
  }

  if (isLogs && !isInOverflow.isLogsOverflow && !errors.logs) {
    const logsColumns = getLogColumns(
      labelIndexMap,
      logsAttributionData,
      logsAttributionDataTotal,
      logsBillingDataTotal,
      isPartialData,
      logsTotalBillPanelData,
      attributionLabels
    );
    tableDF.fields.push(...logsColumns.tableFields);
    csvDF.fields.push(...logsColumns.csvFields);
  }

  if (isTraces && !isInOverflow.isTracesOverflow && !errors.traces) {
    const tracesColumns = getTracesColumns(
      labelIndexMap,
      tracesAttributionData,
      tracesAttributionDataTotal,
      tracesBillingDataTotal,
      isPartialData,
      tracesTotalBillPanelData,
      attributionLabels
    );
    tableDF.fields.push(...tracesColumns.tableFields);
    csvDF.fields.push(...tracesColumns.csvFields);
  }

  const noPartialData = Object.values(isPartialData).every((value) => !value);
  // Table Add total cost dollars per label value to DataFrame
  const metricsCostColumns = tableDF.fields.find((field) => field.name === FIELD_NAME_APPROXIMATE_COST('Metrics'));
  const totalCostLogs = tableDF.fields.find((field) => field.name === FIELD_NAME_APPROXIMATE_COST('Logs'));
  const totalCostTraces = tableDF.fields.find((field) => field.name === FIELD_NAME_APPROXIMATE_COST('Traces'));
  if (noPartialData && metricsCostColumns && totalCostLogs && totalCostTraces) {
    const index = attributionLabels.length;
    const field = totalCostFieldColumn(
      metricsCostColumns.values || [],
      totalCostLogs.values || [],
      totalCostTraces.values || []
    );
    tableDF.fields = [...tableDF.fields.slice(0, index), field, ...tableDF.fields.slice(index)];
  }

  // Update whole dataFrame length to display all values in the table
  csvDF.length = csvLabelFields.values.length;
  tableDF.length = tableLabelFields.values.length;

  return { tableData: [[tableDF]], csvData: [[csvDF]], isInOverflow };
};

export function getMetricColumns(
  metricData: DataFrame,
  metricTotalBillPanelData: PanelData,
  addCostColumn: boolean,
  labelLength: number
) {
  const totalCostMetrics: number[] = [];
  const csvFields = [];
  const tableFields = [];
  const metricSeriesField = metricData.fields.find((field) => field.name === FIELD_NAME_BILLABLE_METRIC);
  if (metricSeriesField) {
    const billableField = cloneDeep(metricSeriesField);
    // Add missing values to the billable series to match the total label length
    if (labelLength > billableField.values.length) {
      const diff = labelLength - billableField.values.length;
      for (let i = 0; i < diff; i++) {
        billableField.values.push(0);
      }
    }

    csvFields.push(billableField);
    tableFields.push(billableField);

    if (addCostColumn) {
      const metricsBillableSeriesSum = billableField.values.reduce(
        (acc, value) => acc + (Number.isInteger(value) ? value : 0),
        0
      );
      const totalMetricsBill = getTotalCost(metricTotalBillPanelData?.series[0]?.fields);

      // Billable Series Dollar values converted to currency
      billableField.values.forEach((value) => {
        const dollarValue = unFormatDollarCost(value, metricsBillableSeriesSum, totalMetricsBill);
        totalCostMetrics.push(dollarValue);
      });
      const metricsCostColumn: Field = {
        name: FIELD_NAME_APPROXIMATE_COST('Metrics'),
        type: FieldType.number,
        config: { max: Math.max(...totalCostMetrics) },
        values: totalCostMetrics,
      };
      csvFields.push(metricsCostColumn);
      tableFields.push(metricsCostColumn);
    }
  }
  return { csvFields, tableFields };
}

export function getLogColumns(
  labelIndexMap: Record<string, number>,
  logsAttributionData: PanelData,
  logsAttributionDataTotal: PanelData,
  logsBillingDataTotal: PanelData,
  isPartialData: PartialData,
  logsTotalBillPanelData: PanelData,
  attributionLabels: string[]
) {
  const totalCostLogs: number[] = [];
  const tableFields = [];
  const csvFields = [];
  // Create Logs billable series values from matching labels
  const logsBillableSeriesValues = getFieldValuesFromMatchingAllLabels(
    logsAttributionData,
    labelIndexMap,
    attributionLabels
  );

  // Calculate portion of bytes as a portional to the total GiB from the billing dashboard
  // Attribution total bytes
  const logsAttributionDataTotalValue = logsAttributionDataTotal.series[0].fields.find((field) => {
    return field.name === FIELD_NAME_VALUE;
  })?.values[0];

  // Billing dashboard total GiB
  const logsBillingDataTotalValue = logsBillingDataTotal.series[0].fields.find((field) => {
    return field.name === 'grafanacloud_logs_instance_usage';
  })?.values[0];

  // Logs proportional series values
  const logsProportionalSeriesValues = logsBillableSeriesValues.map((value) => {
    return seriesBytesPerBillingBytes(value, logsAttributionDataTotalValue, logsBillingDataTotalValue);
  });

  const hasBilling = !isPartialData.isLogsPartial && logsBillingDataTotalValue;

  const logsField = {
    name: FIELD_NAME_BYTES('Logs', isPartialData.isLogsPartial),
    type: FieldType.number,
    config: {},
    values: hasBilling ? logsProportionalSeriesValues : logsBillableSeriesValues,
  };
  tableFields.push(logsField);
  csvFields.push(logsField);

  // Get Total logs bill
  const totalLogsBill = getTotalCost(logsTotalBillPanelData.series[0].fields);

  // Calculate sum of logs billable series
  const totalLogsBillableSum = logsBillableSeriesValues.reduce((acc, value) => acc + value, 0);

  // Calculate dollar cost of billable series as a portional to the sum
  logsBillableSeriesValues.map((value) => {
    const dollarValue = unFormatDollarCost(value, totalLogsBillableSum, totalLogsBill);
    totalCostLogs.push(dollarValue);
    return formatDollarCost(dollarValue);
  });

  // Add cost and dollars only for full dataset
  if (hasBilling) {
    const costField = {
      name: FIELD_NAME_APPROXIMATE_COST('Logs'),
      type: FieldType.number,
      config: { max: Math.max(...totalCostLogs) },
      values: totalCostLogs,
    };
    tableFields.push(costField);
    csvFields.push(costField);
  }
  return { csvFields, tableFields };
}

export function totalCostFieldColumn(totalCostMetrics: number[], totalCostLogs: number[], totalCostTraces: number[]) {
  let totalCostValuesFormatted: string[] = [];
  let totalCostValues: number[] = [];

  // Iterate through the arrays and sum the corresponding values
  for (let i = 0; i < totalCostMetrics.length; i++) {
    totalCostValues.push(totalCostMetrics[i] + totalCostLogs[i] + totalCostTraces[i]);
    totalCostValuesFormatted.push(formatDollarCost(totalCostMetrics[i] + totalCostLogs[i] + totalCostTraces[i]));
  }

  return {
    name: TABLE_COLUMNS.Total,
    type: FieldType.number,
    config: { min: Math.min(...totalCostValues), max: Math.max(...totalCostValues) },
    values: totalCostValues,
  };
}

export function getTracesColumns(
  labelIndexMap: Record<string, number>,
  tracesAttributionData: PanelData,
  tracesAttributionDataTotal: PanelData,
  tracesBillingDataTotal: PanelData,
  isPartialData: PartialData,
  tracesTotalBillPanelData: PanelData,
  attributionLabels: string[]
) {
  const totalCostTraces: number[] = [];
  const csvFields = [];
  const tableFields = [];
  // Create traces billable series values from matching labels
  const tracesBillableSeriesValues = getFieldValuesFromMatchingAllLabels(
    tracesAttributionData,
    labelIndexMap,
    attributionLabels
  );

  // Calculate portion of bytes as a portional to the total GiB from the billing dashboard
  // Attribution total bytes
  const tracesAttributionDataTotalValue = tracesAttributionDataTotal.series[0].fields.find((field) => {
    return field.name === FIELD_NAME_VALUE;
  })?.values[0];

  // Billing dashboard total GiB
  const tracesBillingDataTotalValue = tracesBillingDataTotal.series[0].fields.find((field) => {
    return field.name === 'grafanacloud_traces_instance_usage';
  })?.values[0];

  // Logs proportional series values
  const tracesProportionalSeriesValues = tracesBillableSeriesValues.map((value) => {
    return seriesBytesPerBillingBytes(value, tracesAttributionDataTotalValue, tracesBillingDataTotalValue);
  });

  const hasBilling = !isPartialData.isTracesPartial && tracesBillingDataTotalValue;

  const tracesField = {
    name: FIELD_NAME_BYTES('Traces', isPartialData.isTracesPartial),
    type: FieldType.number,
    config: {},
    values: hasBilling ? tracesProportionalSeriesValues : tracesBillableSeriesValues,
  };
  csvFields.push(tracesField);
  tableFields.push(tracesField);

  // Get Total traces bill
  const totalTracesBill = getTotalCost(tracesTotalBillPanelData.series[0].fields);

  // Calculate sum of traces billable series
  const totalTracesBillableSum = tracesBillableSeriesValues.reduce((acc, value) => acc + value, 0);

  // Calculate dollar cost of billable series as a portional to the sum
  tracesBillableSeriesValues.forEach((value) => {
    const dollarValue = unFormatDollarCost(value, totalTracesBillableSum, totalTracesBill);
    totalCostTraces.push(dollarValue);
    return formatDollarCost(dollarValue);
  });

  // Add total cost and dollars only for full dataset
  if (hasBilling) {
    // Table add traces cost to DataFrame
    const field = {
      name: FIELD_NAME_APPROXIMATE_COST('Traces'),
      type: FieldType.number,
      config: { max: Math.max(...totalCostTraces) },
      values: totalCostTraces,
    };
    csvFields.push(field);
    tableFields.push(field);
  }
  return { csvFields, tableFields };
}

export const getAllLabelValueCombos = (
  panelData: PanelData,
  labels: string[],
  previousValues: Record<string, number>
) => {
  // Loop through the series and add any new label combinations found
  const newRows: string[][] = [];
  const newKeys: string[] = [];
  for (let i = 0; i < labels.length; i++) {
    newRows.push([]);
  }

  let isInOverflow = false;
  panelData.series.forEach((series) => {
    series.fields.forEach((field) => {
      if (field.name === FIELD_NAME_VALUE) {
        if (field.labels) {
          const { key: labelKey, values: labelValues } = getKeyFromLabels(labels, field);
          if (previousValues[labelKey] === undefined && !newKeys.includes(labelKey)) {
            labelValues.forEach((labelValue, i) => {
              if (labelValue === OVERFLOW_VALUE) {
                isInOverflow = true;
              }
              newRows[i].push(labelValue);
            });
            newKeys.push(labelKey);
          }
        }
      }
    });
  });
  // Returning an empty array will signal to the caller that a value given to the labels
  // is in overflow, and that we can't do attributions for this product
  if (isInOverflow) {
    return [];
  }
  return newRows;
};

export type IsOverflow = {
  isMetricsOverflow: boolean;
  isLogsOverflow: boolean;
  isTracesOverflow: boolean;
};

// Gets all the labels, with the metric ordering as precedence
export function getAllLabelColumnValues(
  metricData: DataFrame | null,
  logsAttributionData: PanelData,
  tracesAttributionData: PanelData,
  attributionLabels: string[],
  hasErrors: ProductErrors
) {
  const isInOverflow = { isMetricsOverflow: false, isLogsOverflow: false, isTracesOverflow: false };
  const labelIndexMap: Record<string, number> = {};
  const labelValues: string[][] = attributionLabels.map(() => []);
  const metricsFields = metricData
    ? attributionLabels.map((label) => metricData.fields.find((field) => field.name === formatMetricLabelPrefix(label)))
    : [];
  const metricLabelsCount = metricsFields[0]?.values.length || 0;

  isInOverflow.isMetricsOverflow = metricsFields.some((field) =>
    field?.values.some((value) => value === OVERFLOW_VALUE)
  );
  if (!isInOverflow.isMetricsOverflow && !hasErrors.metrics) {
    metricsFields.forEach((field, i) => {
      labelValues[i] = field?.values ? [...field.values] : [];
    });
    for (let i = 0; i < metricLabelsCount; i++) {
      const keyValues = metricsFields.map((field) => field?.values[i]);
      labelIndexMap[makeRowKey(Object.values(keyValues))] = i;
    }
  }

  function addNewRows(attributionData: PanelData, overflowKey: keyof typeof isInOverflow, startLength: number) {
    const newLabelCombos = getAllLabelValueCombos(attributionData, attributionLabels, labelIndexMap);
    const newLabelsCombosCount = newLabelCombos[0]?.length || 0;
    isInOverflow[overflowKey] = noLabelsToAdd(newLabelCombos, attributionLabels);
    if (!isInOverflow[overflowKey]) {
      labelValues.forEach((_values, i) => {
        labelValues[i].push(...newLabelCombos[i]);
      });
      for (let i = 0; i < newLabelsCombosCount || 0; i++) {
        const key = makeRowKey(newLabelCombos.map((values) => values[i]));
        labelIndexMap[key] = startLength + i;
      }
    }
    return newLabelsCombosCount;
  }

  const newLogsCount = hasErrors.logs ? 0 : addNewRows(logsAttributionData, 'isLogsOverflow', metricLabelsCount);
  if (!hasErrors.traces) {
    addNewRows(tracesAttributionData, 'isTracesOverflow', metricLabelsCount + newLogsCount);
  }

  return { labelValues, labelIndexMap, isInOverflow };
}

function noLabelsToAdd(newLabels: string[][], attributionLabels: string[]) {
  return !newLabels.length && Boolean(attributionLabels.length);
}

// It's possible the currently configured attribution label is not present in the metricAttributionsFrame
// If that's the case, grouping by the label will end up with null results, so add the label with 'unattributed' values instead
export function addFieldForLabelIfMissing(metricAttributionsFrame: DataFrame, labels: string[]) {
  labels.forEach((label) => {
    const labelField = metricAttributionsFrame.fields.find((field) => field.name === formatMetricLabelPrefix(label));
    if (!labelField) {
      const df = metricAttributionsFrame.fields.find((field) => field.name === BILLABLE_SERIES);
      metricAttributionsFrame.fields.push({
        name: formatMetricLabelPrefix(label),
        config: {},
        type: FieldType.string,
        values: df?.values.map(() => '') || [],
      });
    } else if (!labelField.values.some((value) => value !== undefined)) {
      labelField.values = labelField.values.map(() => '');
    }
  });
  return metricAttributionsFrame;
}

export const convertMetricAttributionsToDataFrame = (records: Attribution[], labels: string[]): DataFrame => {
  if (!records.length) {
    return {
      fields: [],
      length: 0,
    };
  }
  const typeMapping = new Map<string, FieldType>();
  typeMapping.set(BILLABLE_SERIES, FieldType.number);

  const attributionDimensions: DimLabel[] = labels.map((label) => formatMetricLabelPrefix(label));
  const valueFields: FixedLabel[] = [MONTH_ISO, BILLABLE_SERIES];
  const columns: Label[] = [...attributionDimensions, ...valueFields];

  const fields: Field[] = [];
  columns.forEach((column) => {
    const field: Field = {
      name: column,
      type: typeMapping.get(column) ?? FieldType.string,
      values: records.map((record) => record[column]),
      config: {},
    };
    fields.push(field);
  });

  return {
    fields: fields,
    length: records.length,
  };
};
