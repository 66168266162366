import React from 'react';
import { Button, Stack, Alert, TextLink } from '@grafana/ui';
import { testIds } from 'components/testIds';
import { useStyles } from 'styles';
import { ROUTES } from 'constants/routes';
import { StatsScene } from './StatsScene';
import { TableScene } from './TableScene';
import { GrotEmpty } from 'components/GrotEmpty/GrotEmpty';
import { downloadCSV } from 'utils/utils.csv';
import { prefixRoute } from 'utils/utils.routing';
import { useGetData, useGetOrgConfiguration, useIsFullMonthReport } from './hooks/overviewData';
import { SUPPORT_URL } from '../../constants/constant';
import { useMonthPicker } from '../../context/MonthPickerContext';

export const OverviewPage = () => {
  const styles = useStyles();
  const { isCurrentMonth, timeRange } = useMonthPicker();

  const { isPartialData, timestampsDoneLoading } = useIsFullMonthReport(timeRange);
  const orgConfig = useGetOrgConfiguration();
  const {
    dataFrames,
    CSVdata,
    noMetrics,
    noAttributionsEver,
    totalBillPanelData,
    metricTotalBillPanelData,
    logsTotalBillPanelData,
    tracesTotalBillPanelData,
    attributionsLoading,
    isOverflowData,
    errors,
  } = useGetData(orgConfig, timeRange, isCurrentMonth, isPartialData, timestampsDoneLoading);

  // Download CSV file
  const downLoadAttributions = () => {
    return () => {
      downloadCSV(CSVdata, 'attributions', timeRange);
    };
  };

  const titleDate = timeRange.from.format('MMMM yyyy');
  const pageTitle = `${titleDate} billing period`;

  const missingProductString = productNameList(
    noMetrics && !errors.metrics,
    isPartialData.noLogsData && !errors.logs,
    isPartialData.noTracesData && !errors.traces
  );
  const showMissingMetricsWarning = Boolean(!attributionsLoading && missingProductString.length);

  const partialProductString = productNameList(
    false,
    isPartialData.isLogsPartial && !isPartialData.noLogsData,
    isPartialData.isTracesPartial && !isPartialData.noTracesData
  );
  const showMissingLogOrTracesMetricsWarning = Boolean(
    !attributionsLoading && partialProductString.length && !attributionsLoading
  );

  const overflowProductsString = productNameList(
    isOverflowData.isMetricsOverflow,
    isOverflowData.isLogsOverflow,
    isOverflowData.isTracesOverflow
  );
  const hasNoLabels = !orgConfig.loading && orgConfig.attributionLabels.length === 0;
  const hasNoProductUsage = !attributionsLoading && noAttributionsEver;
  const showConfigureAttributions = hasNoLabels || hasNoProductUsage;
  const showCurrentMonthWarning = !attributionsLoading && isCurrentMonth && !showMissingLogOrTracesMetricsWarning;

  const massFatalError = Boolean(errors.metrics && errors.logs && errors.traces);
  const errorsProductString = productNameList(Boolean(errors.metrics), Boolean(errors.logs), Boolean(errors.traces));

  return (
    <div className={styles.tabsContainer} data-testid={testIds.overviewTab.container}>
      <Stack direction={'column'} justifyContent="space-between">
        <Stack direction={'row'} justifyContent="space-between">
          <h2>{pageTitle}</h2>
          <Button
            disabled={!CSVdata}
            variant="secondary"
            fill="outline"
            icon="file-download"
            onClick={downLoadAttributions()}
          >
            Download (CSV)
          </Button>
        </Stack>
      </Stack>
      {overflowProductsString && (
        <Alert title="High cardinality causing data loss" severity="error">
          Attribution reports for {overflowProductsString} cannot be calculated because the maximum allowed label
          cardinality has been exceeded. Please contact{' '}
          <TextLink external href={SUPPORT_URL}>
            support
          </TextLink>{' '}
          for assistance.
        </Alert>
      )}
      {showCurrentMonthWarning && (
        <Alert title="Month Incomplete" severity="info">
          Logs and Traces are available from the start of the month to today. Metrics are currently only calculated at
          the end of the month and therefore there is no data currently present.
        </Alert>
      )}
      {showMissingMetricsWarning && !showCurrentMonthWarning && (
        <Alert title="Missing attributions" severity="info">
          No {missingProductString} attribution data present for this month.
        </Alert>
      )}
      {showMissingLogOrTracesMetricsWarning && (
        <Alert title="Partial data detected" severity="info">
          We&apos;re detecting that cost attribution for {partialProductString} was enabled partway through this month,
          rather than from the month start. We&apos;re showing you the attribution usage over the partial period for
          which cost attribution was enabled. Because we do not have a full month of data, these volumes will not add up
          to the usage on your invoice for {titleDate}
        </Alert>
      )}
      {!showConfigureAttributions && (
        <div>
          <div className={styles.overviewScene} data-testid={testIds.overviewTab.stats}>
            {/* Billing Panels */}
            <StatsScene
              totalBillPanelData={totalBillPanelData}
              metricTotalBillPanelData={metricTotalBillPanelData}
              logsTotalBillPanelData={logsTotalBillPanelData}
              tracesTotalBillPanelData={tracesTotalBillPanelData}
              timeRange={timeRange}
            />
          </div>
        </div>
      )}
      <div className={styles.overviewTableGrid}>
        {/* Attributions tables by labels */}
        {errorsProductString && <Alert title={`An error occurred fetching ${errorsProductString}`} />}
        {!orgConfig.loading && orgConfig.error && <Alert title="An error occurred fetching configuration" />}
        {showConfigureAttributions && (
          <GrotEmpty
            description="Attributions have not been configured or are still processing. It can take up to one day to generate a report."
            cta={{ url: `${prefixRoute(ROUTES.Configuration)}`, text: 'Cost attributions configuration' }}
          />
        )}
        {!massFatalError && !showConfigureAttributions && (
          <TableScene
            dateString={titleDate}
            dataFrames={dataFrames}
            timeRange={timeRange}
            loading={attributionsLoading}
          />
        )}
      </div>
    </div>
  );
};

function productNameList(isMetrics: boolean, isLogs: boolean, isTraces: boolean) {
  const products = [];
  if (isMetrics) {
    products.push('metrics');
  }
  if (isLogs) {
    products.push('logs');
  }
  if (isTraces) {
    products.push('traces');
  }
  if (products.length > 2) {
    const last = products.pop();
    return `${products.join(', ')}, and ${last}`;
  }
  return products.join(' and ');
}
