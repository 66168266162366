import { lastValueFrom } from 'rxjs';
import { transformDataFrame, DataFrame } from '@grafana/data';
import { formatMetricLabelPrefix } from '../utils/utils.formating';

const attributionTransformations = (labels: string[]) => [
  {
    id: 'groupBy',
    options: {
      fields: {
        BillableSeries: {
          aggregations: ['sum'],
          operation: 'aggregate',
        },
        'Month(ISO)': {
          aggregations: [],
          operation: 'groupby',
        },
        ...labels.reduce((newGroup, currentValue) => {
          newGroup[formatMetricLabelPrefix(currentValue)] = {
            aggregations: [],
            operation: 'groupby',
          };
          return newGroup;
        }, {} as any),
      },
    },
  },
  {
    id: 'groupBy',
    options: {
      fields: {
        'BillableSeries (sum)': {
          aggregations: ['first'],
          operation: 'aggregate',
        },
        ...labels.reduce((newGroup, currentValue) => {
          newGroup[formatMetricLabelPrefix(currentValue)] = {
            aggregations: [],
            operation: 'groupby',
          };
          return newGroup;
        }, {} as any),
      },
    },
  },
  {
    id: 'organize',
    options: {
      excludeByName: {},
      includeByName: {},
      indexByName: {},
      renameByName: {
        'BillableSeries (sum) (first)': 'Metrics (billable series)',
        ...labels.reduce((newGroup, currentValue) => {
          newGroup[formatMetricLabelPrefix(currentValue)] = `Label: ${currentValue}`;
          return newGroup;
        }, {} as any),
      },
    },
  },
  {
    id: 'sortBy',
    options: {
      fields: {},
      sort: [
        {
          field: 'Metrics (billable series)',
          desc: true,
        },
      ],
    },
  },
];

export const transformer = async (df: DataFrame[], labels: string[]) => {
  const attributionDataframe = attributionTransformations(labels);
  return await lastValueFrom(transformDataFrame(attributionDataframe, df));
};
