import React, { createContext, ReactNode, useContext, useState } from 'react';

export type ConfigContextType = {
  metricLabels: string[];
  setMetricLabelValues: React.Dispatch<React.SetStateAction<string[]>>;
};

export type LabelsType = {
  metricLabels: string[];
};

export const initialState = {
  metricLabels: '',
  setMetricLabelValues: () => {},
};

type Props = {
  children: ReactNode[] | ReactNode;
};

export const ConfigContext = createContext<ConfigContextType>({
  metricLabels: [],
  setMetricLabelValues: () => {},
});

export const ConfigContextProvider = ({ children }: Props) => {
  const [metricLabels, setMetricLabelValues] = useState<string[]>([]);

  const value = {
    metricLabels,
    setMetricLabelValues,
  };

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};

export const useConfigContext = () => {
  return useContext(ConfigContext);
};
