import { toCSV, DataFrame, type TimeRange } from '@grafana/data';

export const downloadCSV = (data: any, filename: string, forMonth: TimeRange) => {
  const from = forMonth.from.format('YYYY-MM-DD');
  const to = forMonth.to.format('YYYY-MM-DD');
  const blob = new Blob([data], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  let link = document.createElement('a');
  link.download = `${filename}_${from}_${to}.csv`;
  link.href = url;
  link.click();
};

export const mergeCSVs = (csv1: string, csv2: string) => {
  if (!csv1 && !csv2) {
    return '';
  }

  // Split the CSV strings into arrays of rows
  const rows1 = csv1.split('\n').map((row) => row.split(',').map((item) => item.replace(/\r/g, '')));
  const rows2 = csv2.split('\n').map((row) => row.split(',').map((item) => item.replace(/\r/g, '')));

  // Combine the headers
  let headers = rows1[0].concat(rows2[0]);
  const cleanedHeaders = headers.map((item) => item.replace(/\r/g, ''));

  // Combine the data rows
  const dataRows = rows1.slice(1).map((row, i) => row.concat(rows2[i + 1]));

  // Convert the combined data back into a CSV string
  const combinedCSV = [cleanedHeaders, ...dataRows].map((row) => row.join(',')).join('\n');

  return combinedCSV;
};

export const createCSV = (transformedData: DataFrame[][]): string => {
  const maxLength = Math.max(...transformedData.map((dataFrame) => dataFrame[0].length));

  // Loop through the longest data and add empty values to the rest
  transformedData.forEach((dataFrame) => {
    const data = dataFrame[0];
    data.fields.forEach((field) => {
      field.values = field.values.concat(Array(maxLength - field.values.length).fill(''));
    });
  });

  return (
    transformedData.reduce((combinedCSV: string | null, data) => {
      try {
        const transformedData = data;
        const csv = toCSV(transformedData);
        return combinedCSV ? mergeCSVs(combinedCSV, csv) : csv;
      } catch (error) {
        console.error('Error processing CSV:', error);
        return combinedCSV;
      }
    }, null) || ''
  );
};
