import { RuntimeDataSource, sceneUtils } from '@grafana/scenes';
import { Attribution, DimLabel, FixedLabel, isDimLabel, Label } from 'types';
import { DataQueryResponse, Field, FieldType, LoadingState, TestDataSourceResponse } from '@grafana/data';
import { getDimensions } from 'pages/Attributions/dimensions';
import { BILLABLE_SERIES, FIELD_NAME_URL_LINK, MONTH_ISO } from 'constants/constant';
import { Observable } from 'rxjs';

export const registerCustomDatasource = (records: Attribution[]) => {
  const typeMapping = new Map<string, FieldType>();
  typeMapping.set(BILLABLE_SERIES, FieldType.number);

  // Only keep dimension labels (starting with `label_`) and [monthISO, BillableSeries]
  const attributionDimensions: DimLabel[] = getDimensions(records);
  const valueFields: FixedLabel[] = [MONTH_ISO, BILLABLE_SERIES];
  const columns: Label[] = [...attributionDimensions, ...valueFields];

  const fields: Field[] = [];
  columns.forEach((column) => {
    const values: Array<string | number> = [];
    const urlLinks: string[] = [];
    records.forEach((record) => {
      values.push(record[column]);
      urlLinks.push(!record[column] ? `|=~|^$` : `|=|${record[column].toString()}`);
    });
    fields.push({
      name: column,
      type: typeMapping.get(column) ?? FieldType.string,
      values: values,
      config: {},
    });
    if (isDimLabel(column)) {
      fields.push({
        name: FIELD_NAME_URL_LINK(column),
        type: FieldType.string,
        values: urlLinks,
        config: {},
      });
    }
  });

  class MyCustomDS extends RuntimeDataSource {
    query(): Promise<DataQueryResponse> | Observable<DataQueryResponse> {
      return Promise.resolve({
        state: LoadingState.Done,

        data: [
          {
            fields: fields,
            length: records.length,
          },
        ],
      });
    }

    testDatasource(): Promise<TestDataSourceResponse> {
      return Promise.resolve({ status: 'success', message: 'OK' });
    }
  }

  try {
    // Important to specify a unique pluginId and uid for your data source that is unlikely to confict with any other scene app plugin.
    sceneUtils.registerRuntimeDataSource({
      dataSource: new MyCustomDS('grafanacloud-attribution-report', 'grafanacloud-attribution-report'),
    });
  } catch (e) {
    console.warn('Scenes datasource already registered');
  }
};


