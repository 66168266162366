import { formatISO, parseISO } from 'date-fns';
import { dateTime, type TimeRange } from '@grafana/data';
import { createMonthRange } from '../utils/utils.date';

export const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;
export const ONE_HUNDRED_TWENTY_DAYS_IN_MS = ONE_DAY_IN_MS * 120;
export const ONE_MONTH_IN_MS = ONE_DAY_IN_MS * 30;

export const ONE_HUNDRED_TWENTY_DAYS_AGO = dateTime(
  parseISO(formatISO(Date.now() - ONE_HUNDRED_TWENTY_DAYS_IN_MS)).getTime()
);

export const ONE_HOUR_IN_MS = dateTime(parseISO(formatISO(Date.now() - 1000 * 60 * 60)).getTime()); // 1h ago

export const ONE_MONTH_AGO = dateTime(parseISO(formatISO(Date.now() - ONE_MONTH_IN_MS)).getTime());

export const startPrevious7days = dateTime().subtract(7, 'day');
export const startPreviousMonth = dateTime().utc().subtract(1, 'month').startOf('month');
export const endOfPreviousMonth = dateTime().utc().subtract(1, 'month').endOf('month');
export const now = dateTime();
export const nowUtc = now.utc();

export const MAX_QUERYABLE_MONTHS = 3;
export const attributionDateOptions: TimeRange[] = [
  {
    from: dateTime(nowUtc).startOf('month'),
    to: dateTime(nowUtc),
    raw: {
      from: 'now/M',
      to: now,
    },
  },
  ...Array.from(Array(MAX_QUERYABLE_MONTHS)).map((_val, i) => createMonthRange(i + 1)),
];
